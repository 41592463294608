var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { value: _vm.value, persistent: "", width: "1200" }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticStyle: { color: "#fff" } }, [
                _vm._v("Shuttle Items to TPR")
              ]),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "white--text mt-7",
                    attrs: {
                      autofocus: "",
                      "auto-select-first": "",
                      items: _vm.contract.ContractItems,
                      "return-object": "",
                      placeholder: "Select Contract Item",
                      "item-text": "Item.OrderCode",
                      "background-color": "#fff",
                      outlined: "",
                      dense: ""
                    },
                    on: { change: _vm.getSRPList },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(data) {
                          return [
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        data.item.Item.OrderCode +
                                          " - " +
                                          data.item.Item.Description
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedItem,
                      callback: function($$v) {
                        _vm.selectedItem = $$v
                      },
                      expression: "selectedItem"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "table",
            attrs: {
              height: _vm.tableSize,
              headers: _vm.headers,
              items: _vm.tprGroups,
              "items-per-page": _vm.pageSize,
              loading: _vm.loading,
              "loading-text": "Please Wait...Loading TPR Groups",
              search: _vm.search,
              "sort-by": "tprGroup.supplierCode",
              "no-data-text": "Select Contract Item to Display TPR Groups",
              "no-results-text": "No Matching TPR Groups for Supplier",
              "item-key": "ad_group.id",
              "custom-filter": _vm.filterPerfectMatch,
              "fixed-header": "",
              dense: "",
              "hide-default-footer": "",
              "show-select": ""
            },
            on: {
              "update:items": function($event) {
                _vm.tprGroups = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.reg_srp",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.reg_srp_ct) +
                            " @ " +
                            _vm._s(_vm.formatDisplay(item.reg_srp))
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.tpr_srp",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.tpr_srp_ct) +
                            " @ " +
                            _vm._s(_vm.formatDisplay(item.tpr_srp))
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.gp_pct",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatDisplay(item.gp_pct)) + "%")
                      ])
                    ]
                  }
                },
                {
                  key: "item.supplier_code",
                  fn: function(ref) {
                    return undefined
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _c(
            "v-dialog",
            {
              staticClass: "dialog",
              attrs: { persistent: "", width: "500" },
              model: {
                value: _vm.updateRetails,
                callback: function($$v) {
                  _vm.updateRetails = $$v
                },
                expression: "updateRetails"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { color: "#fafafa" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", flat: "", color: "#1C3144" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.updateRetails = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("v-toolbar-title", [_vm._v("Update Retails")])
                    ],
                    1
                  ),
                  _c("v-container", { staticClass: "ma-0 px-4" }, [
                    _c(
                      "section",
                      { staticClass: "ma-0 pa-6" },
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "TPR Retail Count",
                                    type: "number",
                                    "background-color": "white",
                                    outlined: "",
                                    dense: ""
                                  },
                                  model: {
                                    value: _vm.tprSRPCount,
                                    callback: function($$v) {
                                      _vm.tprSRPCount = $$v
                                    },
                                    expression: "tprSRPCount"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-icon", { staticClass: "mb-6" }, [
                              _vm._v("mdi-at")
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "TPR Retail",
                                    prefix: "$",
                                    "background-color": "white",
                                    outlined: "",
                                    dense: "",
                                    type: "number"
                                  },
                                  on: { change: _vm.formatSRP },
                                  model: {
                                    value: _vm.tprSRP,
                                    callback: function($$v) {
                                      _vm.tprSRP = $$v
                                    },
                                    expression: "tprSRP"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { height: "40", color: "#00B24A" },
                          on: { click: _vm.updateGroups }
                        },
                        [
                          _vm._v(" Update"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-1", attrs: { dense: "" } },
                            [_vm._v("mdi-update")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.additionalDates,
                persistent: "",
                width: "400"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                "x-large": "",
                                left: "",
                                color: "#2AACE3"
                              }
                            },
                            [_vm._v("mdi-help-rhombus-outline")]
                          ),
                          _vm._v("Remove Additional TPR Dates? ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "py-4",
                      attrs: { dense: "", justify: "center" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1",
                          attrs: { height: "40" },
                          on: {
                            click: function($event) {
                              _vm.additionalDates = false
                            }
                          }
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text mx-1",
                          attrs: { height: "40", color: "#0c67a5" },
                          on: { click: _vm.clearDates }
                        },
                        [_vm._v("Yes")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { staticClass: "footer", attrs: { flat: "", color: "#fafafa" } },
            [
              _c("v-spacer"),
              _c("v-col", { attrs: { cols: "2", align: "right" } }, [
                _vm.selected.length
                  ? _c("span", { staticClass: "blue--text" }, [
                      _c("i", [
                        _vm._v(_vm._s(_vm.selected.length) + " Selected")
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("v-spacer"),
              _vm.selected.length
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-2",
                      attrs: { height: "40", color: "#0c67a5" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.updateRetails = true
                        }
                      }
                    },
                    [
                      _vm._v(" Update Retails"),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-update")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    disabled: !_vm.selected.length,
                    color: "#00B24A",
                    height: "40"
                  },
                  on: { click: _vm.shuttleToTPR }
                },
                [
                  _vm._v("Shuttle "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-transfer-right")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ShuttleAlert", {
        attrs: { value: _vm.shuttleAlert },
        on: {
          "update:value": function($event) {
            _vm.shuttleAlert = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }