<template>
  <v-dialog :value="value" class="dialog" persistent width="1200">
    <v-card color=#fafafa>
      <v-toolbar flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title style="color:#fff;">Shuttle Items to TPR</v-toolbar-title>
        <v-spacer />
        <v-col cols="4">
          <v-autocomplete autofocus auto-select-first class="white--text mt-7" :items="contract.ContractItems"
            @change="getSRPList" v-model="selectedItem" return-object placeholder="Select Contract Item"
            item-text="Item.OrderCode" background-color="#fff" outlined dense>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="`${data.item.Item.OrderCode} - ${data.item.Item.Description}`"></v-list-item-title>
                  <!-- <v-list-item-subtitle v-html="data.item.Item.Description"></v-list-item-subtitle> -->
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-toolbar>

      <v-data-table :height="tableSize" :headers="headers" :items.sync="tprGroups" :items-per-page="pageSize"
        :loading="loading" loading-text="Please Wait...Loading TPR Groups" :search="search"
        sort-by="tprGroup.supplierCode" no-data-text="Select Contract Item to Display TPR Groups" no-results-text="No Matching TPR Groups for Supplier"
        v-model="selected" class="table" item-key="ad_group.id" :custom-filter="filterPerfectMatch" fixed-header dense hide-default-footer show-select>

        <!-- <template v-slot:top>
          <v-toolbar flat color="#fafafa" class="pt-3">
            <v-col cols="4">
              <v-autocomplete :disabled="!suppliers.length" outlined dense background-color="#fff" :items="suppliers" item-text="description" item-value="id"
                prepend-inner-icon="mdi-filter-outline" label="Filter By Division" v-model="search" clearable :menu-props="{ maxHeight: 260 }">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.description}}</v-list-item-title>
                      <v-list-item-title>
                        <span class="primary--text">{{getShuttleGroupsCount(data.item.id)}} / {{data.item.groups.length}} Groups Shuttled</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-toolbar>
          <v-divider/>
        </template> -->

        <template v-slot:[`item.reg_srp`]="{ item }">
          <span>{{item.reg_srp_ct}} @ {{formatDisplay(item.reg_srp)}}</span>
        </template>

        <template v-slot:[`item.tpr_srp`]="{ item }">
          <span>{{item.tpr_srp_ct}} @ {{formatDisplay(item.tpr_srp)}}</span>
        </template>

        <template v-slot:[`item.gp_pct`]="{ item }">
          <span>{{formatDisplay(item.gp_pct)}}%</span>
        </template>

        <template v-slot:[`item.supplier_code`]="{}">
        </template>

      </v-data-table>

      <v-dialog v-model="updateRetails" class="dialog" persistent width="500">
        <v-card color="#fafafa">
          <v-toolbar dark flat color="#1C3144">
            <v-btn icon dark @click.stop="updateRetails=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Update Retails</v-toolbar-title>
          </v-toolbar>
          <v-container class="ma-0 px-4">
            <section class="ma-0 pa-6">
              <v-row justify="center">
                <v-col cols="5">
                  <v-text-field v-model="tprSRPCount" label="TPR Retail Count" type="number" background-color="white"
                    outlined dense />
                </v-col>
                <v-icon class="mb-6">mdi-at</v-icon>
                <v-col cols="5">
                  <v-text-field @change="formatSRP" v-model="tprSRP" label="TPR Retail"
                    prefix="$" background-color="white" outlined dense type="number" />
                </v-col>
              </v-row>
            </section>
          </v-container>
          <v-divider />
          <v-toolbar flat color="#fafafa">
            <v-spacer />
            <v-btn height="40" color="#00B24A" class="white--text" @click="updateGroups">
              Update<v-icon class="ml-1" dense>mdi-update</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-dialog>

      <v-dialog :value="additionalDates" persistent width="400">
        <v-card class="pa-4">
          <v-row dense justify="center">
            <v-card-title>
              <v-icon x-large left color="#2AACE3">mdi-help-rhombus-outline</v-icon>Remove Additional TPR Dates?
            </v-card-title>
          </v-row>
          <v-row dense justify="center" class="py-4">
            <v-btn height="40" class="mx-1" @click="additionalDates=false">No</v-btn>
            <v-btn height="40" class="white--text mx-1" color="#0c67a5" @click="clearDates">Yes</v-btn>
          </v-row>
        </v-card>
      </v-dialog>

      <v-divider />
      <v-toolbar flat color="#fafafa" class="footer">
        <v-spacer />
        <v-col cols="2" align="right">
          <span v-if="selected.length" class="blue--text">
            <i>{{selected.length}} Selected</i>
          </span>
        </v-col>
        <v-spacer />
        <v-btn v-if="selected.length" @click.stop="updateRetails=true" height="40" color="#0c67a5"
          class="white--text mr-2">
          Update Retails<v-icon right>mdi-update</v-icon>
        </v-btn>
        <v-btn :disabled="!selected.length" color="#00B24A" height="40" class="white--text" @click="shuttleToTPR">Shuttle
          <v-icon right>mdi-transfer-right</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <ShuttleAlert :value.sync="shuttleAlert" />

  </v-dialog>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'

export default {
  name: 'ShuttleToTPR',

  components: {
    ShuttleAlert: () => import('@/components/contracts/shuttle-alert')
  },

  props: ['value', 'contract'],

  mixins: [tableSettings, notification],

  data () {
    return {
      additionalDates: false,
      divisions: [],
      loading: false,
      pageSize: 100,
      regSRP: '',
      search: '',
      selected: [],
      selectedItem: {},
      shuttleAlert: false,
      shuttledGroups: [],
      suppliers: [],
      tprGroups: [],
      tprRetail: '',
      tprSRP: '',
      tprSRPCount: '',
      tprSRPs: [],
      updateRetails: false,
      consentinoGroups: [
        'CPC',
        'CPG',
        'CMA'
      ]
    }
  },

  computed: {
    headers () {
      return [
        { text: 'TPR Group', align: 'left', sortable: 'true', filterable: 'true', value: 'ad_group.name', class: 'black--text' },
        { text: 'Reg SRP', sortable: 'true', filterable: 'true', value: 'reg_srp', class: 'black--text' },
        { text: 'TPR Retail', sortable: 'true', filterable: 'true', value: 'tpr_srp', class: 'black--text' },
        { text: 'TPR GP%', sortable: 'true', filterable: 'true', value: 'gp_pct', class: 'black--text' },
        { value: 'supplier_code' }
      ]
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.selected = []
          this.tprGroups = []
          this.shuttledGroups = []
          this.suppliers = []
          this.selectedItem = {}
        }
      }
    },

    updateRetails: {
      immediate: true,
      handler (value) {
        if (value) {
          this.tprSRP = ''
          this.tprSRPCount = ''
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 315
  },

  methods: {
    filterPerfectMatch (value, search) {
      return value != null && value === search
    },

    clearDates () {
      this.$emit('clear-dates')
      this.additionalDates = false
    },

    formatSRP (value) {
      this.tprSRP = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    formatDisplay (value) {
      return ((value * 100) / 100).toFixed(2)
    },

    getShuttleGroupsCount (supplierCode) {
      return this.shuttledGroups.filter(tprGroup => tprGroup.supplier_code === supplierCode).length
    },

    async getSRPList (selectedItem) {
      let isCosentinoContract = this.consentinoGroups.find(x => x === this.contract.AdGroup.ID)
      this.loading = true
      await this.$shuttles.getTPRGroupSRPs(selectedItem)
        .then(response => {
          response.forEach(tprGroup => {
            tprGroup.supplier_code = tprGroup.supplier.id.toString()
          })
          this.tprGroups = []
          this.tprGroups = response
          this.loading = false
        })
      this.tprGroups = this.tprGroups.filter(item => {
        let isCosentino = this.consentinoGroups.find(x => x === item.ad_group.id)
        if (isCosentinoContract) {
          return isCosentino
        }
        else {
          return !isCosentino
        }
      })

      this.getSuppliers()
    },

    async getSuppliers () {
      await this.$contracts.getSuppliers()
        .then(response => {
          response.forEach(supplier => {
            supplier.groups = []
            supplier.id = supplier.id.toString()
            this.tprGroups.forEach(tprGroup => {
              if (tprGroup.supplier_code === supplier.id) {
                supplier.groups.push(tprGroup)
              }
            })
          })
          this.suppliers = response.filter(supplier => supplier.groups.length)
        })
    },

    async updateGroups () {
      var item = {
        AMAP: this.selectedItem.AMAP,
        AdBB: this.selectedItem.AdBB,
        AdGroupCode: this.selected[0].ad_group.id,
        AdItemContractID: this.selectedItem.AdItemContractID,
        AdScan: this.selectedItem.AdScan,
        Compete: this.selectedItem.Compete,
        ContractID: this.selectedItem.ContractID,
        DealID: this.selectedItem.DealID,
        EBA: this.selectedItem.EBA,
        Item: {
          CaseCost: this.selectedItem.Item.CaseCost,
          Description: this.selectedItem.Item.Description,
          ItemCode: this.selectedItem.Item.ItemCode,
          OrderCode: this.selectedItem.Item.OrderCode,
          Pack: this.selectedItem.Item.Pack,
          PromoGroup: this.selectedItem.Item.PromoGroup,
          SRP: (this.tprSRP) ? this.tprSRP : this.selected[0].tpr_srp,
          SRPCount: (this.tprSRPCount) ? this.tprSRPCount : this.selected[0].tpr_srp_ct,
          Size: this.selectedItem.Item.Size,
          UPC: this.selectedItem.Item.UPC
        },
        PCAF: this.selectedItem.PCAF,
        TPRBB: this.selectedItem.TPRBB,
        TPRScan: this.selectedItem.TPRScan,
        ePayAd: this.selectedItem.ePayAd,
        ePayTPR: this.selectedItem.ePayTPR
      }
      await this.$shuttles.getTPRGroupSRPs(item)
        .then(response => {
          response.forEach(value => {
            this.selected.forEach(tprGroup => {
              if (value.ad_group.id === tprGroup.ad_group.id) {
                tprGroup.tpr_srp_ct = value.tpr_srp_ct
                tprGroup.tpr_srp = value.tpr_srp
                tprGroup.gp_pct = value.gp_pct
              }
            })
          })
        })
      this.selected = []
      this.tprSRP = ''
      this.tprSRPCount = ''
      this.updateRetails = false
    },

    async shuttleToTPR () {
      this.showLoader('Shuttling Groups', true)
      this.shuttling = true
      const selectedGroups = []
      const shuttle = {}

      this.selected.forEach(group => {
        const tprGroup = {
          adGroupCode: group.ad_group.id,
          regSRPCount: group.reg_srp_ct,
          regSRP: group.reg_srp,
          TPR_SRPCount: group.tpr_srp_ct,
          TPR_SRP: group.tpr_srp
        }
        selectedGroups.push(tprGroup)
      })

      shuttle.contractId = this.contract.ContractID
      shuttle.adGroups = selectedGroups
      shuttle.dealBeginDate = this.contract.TPRBeginDate
      shuttle.dealEndDate = this.contract.TPREndDate

      await this.$shuttles.shuttleToTPR(shuttle)
        .then(() => {
          this.showLoader('Complete', false)
          this.shuttleAlert = true
          this.selected.filter(x => {
            var itemIndex = this.tprGroups.indexOf(x)
            this.tprGroups.splice(itemIndex, 1)
          })
        })
      this.selected = []
      if (this.contract.TPRDates.length) {
        setTimeout(() => { this.additionalDates = true }, 2000)
      }
    }
  }
}
</script>
